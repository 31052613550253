/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, Dispatch, SetStateAction } from 'react';
import Company from 'types/company';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { setVAT } from 'src/redux/VATSlice';
import brands from 'types/brands';
import { ProductCategory } from 'types/product';
import ExclusiveBrandTile from './components/ExclusiveBrandTile';
import Link from 'next/link';
import { GTagCategory } from 'types/googleAnalytics';

interface IProps {
  companyID: Company;
  isMenuOpen: boolean;
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>;
  isProductsMenuOpen: boolean;
  setIsProductsMenuOpen: Dispatch<SetStateAction<boolean>>;
  isBrandsMenuOpen: boolean;
  setIsBrandsMenuOpen: Dispatch<SetStateAction<boolean>>;
  isProductStage2Open: boolean;
  setIsProductStage2Open: Dispatch<SetStateAction<boolean>>;
  isProductStage3Open: boolean;
  setIsProductStage3Open: Dispatch<SetStateAction<boolean>>;
  activeLink: {
    name: string;
    link: string;
    children: {
      name: string;
      link: string;
    };
  };
  setActiveLink: Dispatch<
    SetStateAction<{
      name: string;
      link: string;
      children: {
        name: string;
        link: string;
      };
    }>
  >;
  brandItems: brands;
  productCategory: {
    isFetchingData: boolean;
    productCategory: Array<ProductCategory>;
  };
}

const MenuTab: React.FC<IProps> = ({
  companyID,
  isMenuOpen,
  setIsMenuOpen,
  isProductsMenuOpen,
  setIsProductsMenuOpen,
  isBrandsMenuOpen,
  setIsBrandsMenuOpen,
  isProductStage2Open,
  setIsProductStage2Open,
  isProductStage3Open,
  setIsProductStage3Open,
  activeLink,
  setActiveLink,
  brandItems,
  productCategory,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const reduxStore = useAppSelector((state) => state);
  const productCategories = productCategory.productCategory;

  const handleResetOnClick = (): void => {
    setIsMenuOpen(false);
    setIsProductsMenuOpen(false);
    setIsBrandsMenuOpen(false);
    setIsProductStage2Open(false);
    setIsProductStage3Open(false);
    setActiveLink({
      name: '',
      link: '',
      children: {
        name: '',
        link: '',
      },
    });
    document.body.style.overflow = 'auto';
  };

  useEffect(() => {
    window.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        setIsMenuOpen(false);
        setIsProductsMenuOpen(false);
        setIsBrandsMenuOpen(false);
        setIsProductStage2Open(false);
        setIsProductStage3Open(false);
        setActiveLink({
          name: '',
          link: '',
          children: {
            name: '',
            link: '',
          },
        });
        document.body.style.overflow = 'auto';
      }
    });
  }, []);

  const handleClickVAT = (): void => {
    dispatch(setVAT(!reduxStore.vat.showVat));
  };

  return (
    <div
      className={`nav-menu ${isMenuOpen ? 'opened' : ''} ${
        isProductsMenuOpen ? 'nav-menu--product' : ''
      } ${isProductsMenuOpen && isProductStage2Open ? 'nav-menu--stage2' : ''} ${
        isProductsMenuOpen && isProductStage3Open ? 'nav-menu--stage3' : ''
      } ${isBrandsMenuOpen ? 'nav-menu--brand' : ''}`}
    >
      <div className="menu--head">
        <div className="head__main">
          <button
            className={`head__btn ${
              companyID === Company.Ironmongery ? 'hover:text-orange' : 'hover:text-green'
            }`}
            title="Close Menu"
            aria-label="Close Menu"
            type="button"
            onClick={() => {
              setIsMenuOpen(false);
              setIsProductsMenuOpen(false);
              setIsBrandsMenuOpen(false);
              setIsProductStage2Open(false);
              setIsProductStage3Open(false);
              setActiveLink({
                name: '',
                link: '',
                children: {
                  name: '',
                  link: '',
                },
              });
              document.body.style.overflow = 'auto';
            }}
          >
            <span className="head__btn--span">
              <svg
                focusable="false"
                className="head__btn--svg"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
              >
                <path d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"></path>
              </svg>
              <span className="hidden xl:block">Close</span>
            </span>
          </button>
        </div>
        <div className="head--logo">
          <Link href="/">
            <a
              title={companyID !== 1 ? 'ElectricalDirect' : 'IronmongeryDirect'}
              role="button"
              tabIndex={0}
              onClick={handleResetOnClick}
            >
              <img
                src={companyID !== 1 ? '/images/ED-Dark-bg.svg' : '/images/ID-Dark-bg.svg'}
                alt={companyID !== 1 ? 'ElectricalDirect' : 'IronmongeryDirect'}
                className="head--logo__img"
              />
            </a>
          </Link>
        </div>
        <button
          className={isProductsMenuOpen || isBrandsMenuOpen ? 'head__btn--back' : 'hidden'}
          id="menu-back-btn-0"
          aria-expanded="true"
          aria-hidden="true"
          onClick={() => {
            setIsProductsMenuOpen(false);
            setIsBrandsMenuOpen(false);
            setIsProductStage2Open(false);
            setIsProductStage3Open(false);
            setActiveLink({
              name: '',
              link: '',
              children: {
                name: '',
                link: '',
              },
            });
          }}
        >
          <svg
            className="svg-icon rhd-svg-icon rhd-svg-icon--xs"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"></path>
          </svg>
          Back
        </button>
      </div>
      <div className="relative">
        {/* Nav Menu */}
        <nav
          className={`menu-container__nav ${
            isProductsMenuOpen || isBrandsMenuOpen ? 'menu-container__nav--hidden' : ''
          }`}
        >
          <ul className="pb-7/50 text-22px leading-34px font-bold">
            <li>
              <button
                className="w-full font-bold flex justify-between items-center text-white hover:underline"
                id="menu-products"
                data-index="0"
                onClick={() => {
                  setIsProductsMenuOpen(true);
                  ga('send', 'event', GTagCategory.Menu, 'First Level Navigation', 'Products');
                }}
              >
                <p>Products</p>
                <svg
                  className="mt-1 w-3.5 h-3.5 align-middle overflow-visible fill-current inline-block"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                >
                  <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                </svg>
              </button>
            </li>
            <li className="mt-5">
              <button
                className="w-full font-bold flex justify-between items-center text-white hover:underline"
                id="menu-brands"
                data-index="0"
                onClick={() => {
                  setIsBrandsMenuOpen(true);
                  ga('send', 'event', GTagCategory.Menu, 'First Level Navigation', 'Brands');
                }}
              >
                <p>Brands</p>
                <svg
                  className="mt-1 w-3.5 h-3.5 align-middle overflow-visible fill-current inline-block"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                >
                  <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                </svg>
              </button>
            </li>
          </ul>
          <ul className="text-16px leading-18px py-5 border-b-1 border-solid border-t-1 border-black-order-sum-b">
            <li>
              <Link href="/search?query=clearance2023">
                <a
                  className="py-3 px-0 inline-block border-0 bg-transparent font-normal hover:text-white hover:underline"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    handleResetOnClick();
                    ga('send', 'event', GTagCategory.Menu, 'First Level Navigation', 'Clearance');
                  }}
                >
                  Clearance
                </a>
              </Link>
            </li>
            <li>
              <Link href="/browse/new">
                <a
                  className="py-3 px-0 inline-block border-0 bg-transparent font-normal hover:text-white hover:underline"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    handleResetOnClick();
                    ga(
                      'send',
                      'event',
                      GTagCategory.Menu,
                      'First Level Navigation',
                      'New Products'
                    );
                  }}
                >
                  New Products
                </a>
              </Link>
            </li>
            {companyID === Company.Ironmongery && (
              <li>
                <Link href="/free-catalogue">
                  <a
                    className="py-3 px-0 inline-block border-0 bg-transparent font-normal hover:text-white hover:underline"
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      handleResetOnClick();
                      ga(
                        'send',
                        'event',
                        GTagCategory.Menu,
                        'First Level Navigation',
                        'Free Catalogue'
                      );
                    }}
                  >
                    Free Catalogue
                  </a>
                </Link>
              </li>
            )}
            <li>
              <Link href="/blog">
                <a
                  className="py-3 px-0 inline-block border-0 bg-transparent font-normal hover:text-white hover:underline"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    handleResetOnClick();
                    ga('send', 'event', GTagCategory.Menu, 'First Level Navigation', 'Blog');
                  }}
                >
                  Blog
                </a>
              </Link>
            </li>
            <li>
              <Link href="/reasons-to-shop">
                <a
                  className="py-3 px-0 inline-block border-0 bg-transparent font-normal hover:text-white hover:underline"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    handleResetOnClick();
                    ga(
                      'send',
                      'event',
                      GTagCategory.Menu,
                      'First Level Navigation',
                      'Reasons to Shop'
                    );
                  }}
                >
                  Reasons to Shop
                </a>
              </Link>
            </li>
            <li>
              <Link href="/contact">
                <a
                  className="py-3 px-0 inline-block border-0 bg-transparent font-normal hover:text-white hover:underline"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    handleResetOnClick();
                    ga('send', 'event', GTagCategory.Menu, 'First Level Navigation', 'Contact us');
                  }}
                >
                  Contact us
                </a>
              </Link>
            </li>
          </ul>
        </nav>
        {/* Nav Menu When user clicked Products */}
        <nav
          className={`menu-container__nav--products ${
            !isProductsMenuOpen ? 'menu-container__nav--products--hidden mt-0' : ''
          }`}
        >
          <Link href="/browse/all">
            <a
              className="block text-white text-22px leading-34px font-bold hover:underline"
              role="button"
              tabIndex={0}
              onClick={handleResetOnClick}
            >
              Products
            </a>
          </Link>
          <ul className="products-nav__level1">
            {productCategories.length > 0 && (
              <>
                {productCategories.map((category, index) => (
                  <li key={`product-category-${index}`}>
                    {category?.children?.length > 0 ? (
                      <button
                        className={`products-nav__link ${
                          category.name === activeLink.name ? 'active' : ''
                        }`}
                        onClick={() => {
                          setActiveLink({
                            name: category.name,
                            link: category.webUrl,
                            children: {
                              name: '',
                              link: '',
                            },
                          });
                          setIsProductStage2Open(true);
                          ga(
                            'send',
                            'event',
                            GTagCategory.Menu,
                            'Second Level Navigation',
                            category.name
                          );
                        }}
                      >
                        {category.name}
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                          <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                        </svg>
                      </button>
                    ) : (
                      <Link href={category.webUrl}>
                        <a
                          className="products-nav__link"
                          role="button"
                          tabIndex={0}
                          onClick={() => {
                            handleResetOnClick();
                            ga(
                              'send',
                              'event',
                              GTagCategory.Menu,
                              'Second Level Navigation',
                              category.name
                            );
                          }}
                        >
                          {category.name}
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                            <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                          </svg>
                        </a>
                      </Link>
                    )}
                    <nav
                      className={
                        isProductStage2Open && category.name === activeLink.name
                          ? 'nav--products-stage2'
                          : 'hidden'
                      }
                    >
                      {isProductStage2Open && category.name === activeLink.name && (
                        <>
                          <Link href={activeLink.link}>
                            <a
                              className="products-stage2__heading"
                              role="button"
                              tabIndex={0}
                              onClick={handleResetOnClick}
                            >
                              <span className="block text-white text-16px leading-24px font-bold">
                                {activeLink.name}
                              </span>
                            </a>
                          </Link>
                          <ul>
                            {category?.children?.map((childrenLevel2, index) => (
                              <li key={`product-category-level2-${index}`}>
                                {childrenLevel2.children && childrenLevel2.children.length > 0 ? (
                                  <button
                                    className={`products-nav__link ${
                                      childrenLevel2.name === activeLink.children.name
                                        ? 'active'
                                        : ''
                                    }`}
                                    onClick={() => {
                                      setActiveLink({
                                        ...activeLink,
                                        children: {
                                          name: childrenLevel2.name,
                                          link: childrenLevel2.webUrl,
                                        },
                                      });
                                      ga(
                                        'send',
                                        'event',
                                        GTagCategory.Menu,
                                        'Second Level Navigation More Headers',
                                        childrenLevel2.name
                                      );
                                      setIsProductStage3Open(true);
                                    }}
                                  >
                                    {childrenLevel2.name}
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                      <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                                    </svg>
                                  </button>
                                ) : (
                                  <></>
                                )}
                                <nav
                                  className={
                                    isProductStage3Open &&
                                    childrenLevel2.name === activeLink.children.name
                                      ? 'nav--products-stage3'
                                      : 'hidden'
                                  }
                                >
                                  {isProductStage3Open &&
                                    childrenLevel2.name === activeLink.children.name && (
                                      <>
                                        <Link href={activeLink.children.link}>
                                          <a
                                            className="products-stage2__heading"
                                            role="button"
                                            tabIndex={0}
                                            onClick={handleResetOnClick}
                                          >
                                            <span className="block text-white text-16px leading-24px font-bold">
                                              {activeLink.children.name}
                                            </span>
                                          </a>
                                        </Link>
                                        <ul>
                                          {childrenLevel2.children?.map((childrenLevel3, index) => (
                                            <li key={`product-category-level3-${index}`}>
                                              <Link href={childrenLevel3.webUrl}>
                                                <a
                                                  className="products-nav__link"
                                                  role="button"
                                                  tabIndex={0}
                                                  onClick={() => {
                                                    handleResetOnClick();
                                                    ga(
                                                      'send',
                                                      'event',
                                                      GTagCategory.Menu,
                                                      'Second Level Navigation More Sub',
                                                      childrenLevel3.name
                                                    );
                                                  }}
                                                >
                                                  {childrenLevel3.name}
                                                </a>
                                              </Link>
                                            </li>
                                          ))}
                                          <li>
                                            <Link href={activeLink.children.link}>
                                              <a
                                                className="products-nav__link"
                                                role="button"
                                                tabIndex={0}
                                                onClick={() => {
                                                  handleResetOnClick();
                                                  ga(
                                                    'send',
                                                    'event',
                                                    GTagCategory.Menu,
                                                    'Second Level Navigation More Sub - View All',
                                                    'View All'
                                                  );
                                                }}
                                              >
                                                View All
                                              </a>
                                            </Link>
                                          </li>
                                        </ul>
                                      </>
                                    )}
                                </nav>
                              </li>
                            ))}
                            <li>
                              <Link href={activeLink.link}>
                                <a
                                  className="products-nav__link"
                                  role="button"
                                  tabIndex={0}
                                  onClick={() => {
                                    handleResetOnClick();
                                    ga(
                                      'send',
                                      'event',
                                      GTagCategory.Menu,
                                      'Second Level Navigation - View All',
                                      'View All'
                                    );
                                  }}
                                >
                                  View All
                                </a>
                              </Link>
                            </li>
                          </ul>
                        </>
                      )}
                    </nav>
                  </li>
                ))}
                <Link href="/browse/new">
                  <a
                    title="New Products"
                    className="products-nav__link"
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      handleResetOnClick();
                      ga(
                        'send',
                        'event',
                        GTagCategory.Menu,
                        'Second Level Navigation',
                        'New Products'
                      );
                    }}
                  >
                    New Products
                  </a>
                </Link>
                <Link href="/search?query=clearance2023">
                  <a
                    title="Clearance"
                    className="products-nav__link"
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      handleResetOnClick();
                      ga(
                        'send',
                        'event',
                        GTagCategory.Menu,
                        'Second Level Navigation',
                        'Clearance'
                      );
                    }}
                  >
                    Clearance
                  </a>
                </Link>
                <li>
                  <Link href="/browse/all">
                    <a
                      title="View All"
                      className="products-nav__link"
                      role="button"
                      tabIndex={0}
                      onClick={handleResetOnClick}
                    >
                      View All
                    </a>
                  </Link>
                </li>
              </>
            )}
          </ul>
        </nav>

        {/* Nav Menu when user clicked brands */}
        <nav
          className={`menu-container__nav--brands ${
            !isBrandsMenuOpen ? 'menu-container__nav--brands--hidden mt-0' : ''
          }`}
        >
          <span className="block text-white m-0 font-bold text-18px leading-7 md:text-22px md:leading-8">
            Brands
          </span>

          {companyID === Company.Ironmongery && (
            <div className="menu--brands">
              <span className="block text-white m-0 font-bold text-16px leading-6 md:text-18px md:leading-7">
                Our Exclusive Brands
              </span>
              <div
                className="flex mt-2 flex-wrap"
                aria-label="Submenu for Our Exclusive Brands flex"
              >
                {brandItems?.ownBrands?.map((ownBrand) => (
                  <div className="mr-3 mb-3 rounded-md overflow-hidden" key={ownBrand.brandName}>
                    <ExclusiveBrandTile ownBrand={ownBrand} handleClickReset={handleResetOnClick} />
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="menu--brands">
            <span className="block text-white m-0 font-bold text-16px leading-6 md:text-18px md:leading-7">
              All Brands A - Z
            </span>
            <ul className="rhd-menu__brands__a2z" aria-label="Submenu for All Brands A - Z">
              {!brandItems.isFetchingData &&
                brandItems?.brands?.length > 0 &&
                brandItems.brands.map((brand, index) => {
                  const isTopBrand = brandItems.popularBrands.find(
                    (item) => item.brandName === brand.brandName
                  );
                  let url = isTopBrand
                    ? brand.brandName.replace(/ /g, '')
                    : brand.brandName.replace(/ /g, '%20');
                  url = url.replace(/&/g, '202620');
                  const currentAlpha = brand.brandName.charAt(0);
                  const isDigit = isNaN(Number(brand.brandName.charAt(0))) ? false : true;

                  if (index === 0) {
                    return (
                      <React.Fragment key={`brand-items-${index}`}>
                        <li className="rhd-menu__brands__a2z__heading" key={`brand-items-${index}`}>
                          <span>{isDigit ? '#' : 'A'}</span>
                        </li>
                        <li className="rhd-menu__brands__a2z__link">
                          <Link href={`/brands/${url.toLowerCase()}`}>
                            <a
                              className="text-white inline-block border-none bg-none no-underline font-normal hover:underline"
                              title={brandItems.brands[0].brandName}
                              role="button"
                              tabIndex={0}
                              onClick={() => {
                                handleResetOnClick();
                                ga(
                                  'send',
                                  'event',
                                  GTagCategory.Menu,
                                  'Second Level Navigation',
                                  brandItems.brands[0].brandName
                                );
                              }}
                            >
                              {brandItems.brands[0].brandName}
                            </a>
                          </Link>
                        </li>
                      </React.Fragment>
                    );
                  } else if (
                    !isDigit &&
                    currentAlpha !==
                      brandItems.brands[index !== 0 ? index - 1 : index].brandName.charAt(0)
                  ) {
                    return (
                      <React.Fragment key={`brand-items-${index}`}>
                        <li className="rhd-menu__brands__a2z__heading">
                          <span>{currentAlpha}</span>
                        </li>
                        <li className="rhd-menu__brands__a2z__link">
                          <Link href={`/brands/${url.toLowerCase()}`}>
                            <a
                              className="text-white inline-block border-none bg-none no-underline font-normal hover:underline"
                              title={brand.brandName}
                              role="button"
                              tabIndex={0}
                              onClick={() => {
                                handleResetOnClick();
                                ga(
                                  'send',
                                  'event',
                                  GTagCategory.Menu,
                                  'Second Level Navigation',
                                  brand.brandName
                                );
                              }}
                            >
                              {brand.brandName}
                            </a>
                          </Link>
                        </li>
                      </React.Fragment>
                    );
                  }

                  return (
                    <li className="rhd-menu__brands__a2z__link" key={`brand-items-${index}`}>
                      <Link href={`/brands/${url.toLowerCase()}`}>
                        <a
                          className="text-white inline-block border-none bg-none no-underline font-normal hover:underline"
                          title={brand.brandName}
                          role="button"
                          tabIndex={0}
                          onClick={() => {
                            handleResetOnClick();
                            ga(
                              'send',
                              'event',
                              GTagCategory.Menu,
                              'Second Level Navigation',
                              brand.brandName
                            );
                          }}
                        >
                          {brand.brandName}
                        </a>
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </div>
        </nav>
      </div>
      <div
        className={`menu--bottom ${
          isProductsMenuOpen || isBrandsMenuOpen ? 'menu--bottom--hidden' : ''
        }`}
        aria-hidden="false"
      >
        <Link href="/quick-order">
          <a
            className={`cta-btn${
              companyID === Company.Ironmongery ? '--id' : '--ed'
            } bottom-nav__btn`}
            role="button"
            tabIndex={0}
            onClick={() => {
              handleResetOnClick();
              ga(
                'send',
                'event',
                GTagCategory.Menu,
                'First Level Navigation',
                'Order by Catalogue Code'
              );
            }}
          >
            Order by Catalogue Code
          </a>
        </Link>
        <div className="mt-15px" id="ReactHandleVAT">
          <button
            type="button"
            id="vatBtn"
            className={`cta-btn${
              companyID === Company.Ironmongery ? '--id' : '--ed'
            } bottom-nav__btn`}
            title="Click to show prices including VAT."
            onClick={() => {
              handleClickVAT();
              ga(
                'send',
                'event',
                GTagCategory.Menu,
                'First Level Navigation',
                reduxStore.vat.showVat ? 'Hide VAT' : 'Show VAT'
              );
            }}
          >
            {reduxStore.vat.showVat ? 'Hide VAT' : 'Show VAT'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MenuTab;
