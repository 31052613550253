import { DYCartItem } from 'types/dynamicYield';
import getCookies from '@utils/getCookies';

declare const DY: any;

const mergeDYCartItems = (
  priceBreaks: any[],
  dyCartItems: DYCartItem[],
  dyCartItem: DYCartItem
): DYCartItem[] => {
  let updatedCart = [];

  // check if sku code is already in the cart
  const existingProductIndex = dyCartItems.findIndex(
    (cartItem) => cartItem.productId === dyCartItem.productId
  );

  if (existingProductIndex > -1) {
    const existingCartItem = dyCartItems[existingProductIndex];
    updatedCart = [...dyCartItems];
    updatedCart.splice(existingProductIndex, 1);
    updatedCart.push({
      productId: existingCartItem.productId,
      quantity: existingCartItem.quantity + dyCartItem.quantity,
      itemPrice: getPrice(
        priceBreaks,
        dyCartItem.itemPrice,
        existingCartItem.quantity + dyCartItem.quantity
      ),
    });
  } else {
    updatedCart = [
      ...dyCartItems,
      {
        productId: dyCartItem.productId,
        quantity: dyCartItem.quantity,
        itemPrice: getPrice(priceBreaks, dyCartItem.itemPrice, dyCartItem.quantity),
      },
    ];
  }

  return updatedCart;
};

const getPrice = (priceBreaks: any[], itemPrice: number, quantity: number): number => {
  let price = itemPrice;
  priceBreaks.forEach((priceBreak) => {
    if (quantity >= priceBreak.quantity) {
      price = priceBreak.price || priceBreak.currentPrice;
    }
  });
  return Math.round(price * 100) / 100;
};

const dyAddToCart = (
  itemPriceBreaks: any[],
  basketItems: any[],
  newBasketItem: DYCartItem
): void => {
  const dyCartItems: DYCartItem[] = [];
  if (getCookies('authToken') !== '' && getCookies('authEmail') !== '') {
    if (basketItems.length > 0) {
      basketItems.forEach((basketItem) => {
        dyCartItems.push({
          productId: basketItem.skuCode,
          quantity: basketItem.quantity,
          itemPrice: getPrice(
            basketItem.prices.vatPriceBreaks,
            basketItem.prices.vatPrice,
            basketItem.quantity
          ),
        });
      });
    }
  } else {
    const existingBasket = localStorage.getItem('guestBasket');
    if (existingBasket) {
      const parsedExistingBasket = JSON.parse(existingBasket);
      if (parsedExistingBasket.length > 0) {
        parsedExistingBasket.forEach((basketItem) => {
          dyCartItems.push({
            productId: basketItem.skuCode,
            quantity: basketItem.quantity,
            itemPrice: getPrice(
              basketItem.prices.vatPriceBreaks,
              basketItem.prices.vatPrice,
              basketItem.quantity
            ),
          });
        });
      }
    }
  }

  const updatedCart = mergeDYCartItems(itemPriceBreaks, dyCartItems, {
    productId: newBasketItem.productId,
    quantity: newBasketItem.quantity,
    itemPrice: newBasketItem.itemPrice,
  });

  const newBasketItemIndex = updatedCart.findIndex(
    (cartItem) => cartItem.productId === newBasketItem.productId
  );
  const updatedQuantity = updatedCart[newBasketItemIndex].quantity;

  DY.API('event', {
    name: 'Add to Cart',
    properties: {
      dyType: 'add-to-cart-v1',
      value: Number(
        (
          getPrice(itemPriceBreaks, newBasketItem.itemPrice, updatedQuantity) *
          newBasketItem.quantity
        ).toFixed(2)
      ),
      currency: 'GBP',
      productId: newBasketItem.productId,
      quantity: newBasketItem.quantity,
      cart: updatedCart,
    },
  });

  // console.log({
  //   name: 'Add to Cart',
  //   properties: {
  //     dyType: 'add-to-cart-v1',
  //     value: Number(
  //       (
  //         getPrice(itemPriceBreaks, newBasketItem.itemPrice, updatedQuantity) *
  //         newBasketItem.quantity
  //       ).toFixed(2)
  //     ),
  //     currency: 'GBP',
  //     productId: newBasketItem.productId,
  //     quantity: newBasketItem.quantity,
  //     cart: updatedCart,
  //   },
  // });
};

export default dyAddToCart;
