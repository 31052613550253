import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Company from 'types/company';

interface setBrandProps {
  companyID: Company;
  baseUrl: string | undefined;
  apiKey: string | undefined;
}

const initialState = {
  ownBrands: [],
  popularBrands: [],
  brands: [],
  isFetchingData: true,
};

export const setBrands = createAsyncThunk('brand/setBrands', async (param: setBrandProps) => {
  const { companyID, baseUrl, apiKey } = param;
  const response = await axios({
    method: 'GET',
    url: `${baseUrl}/Product/Brands/All?companyID=${companyID}`,
    headers: {
      // universe: companyID === Company.Ironmongery ? 'ironmongery' : 'electrical',
      'ocp-apim-subscription-key': apiKey,
    },
  });
  return response.data;
});

export const brandSlice = createSlice({
  name: 'brand',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setBrands.pending, (state) => {
        // Add user to the state array
        state.isFetchingData = true;
      })
      .addCase(setBrands.fulfilled, (state, action) => {
        // Add user to the state array
        state.brands = action.payload.brands;
        state.ownBrands = action.payload.ownBrands;
        state.popularBrands = action.payload.popularBrands;
        state.isFetchingData = false;
      });
  },
});

export default brandSlice.reducer;
