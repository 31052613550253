import { useEffect, useState, useCallback } from 'react';
import { useEmblaCarousel } from 'embla-carousel/react';
import { PrevButton, NextButton } from './carouselButton';
import Company from 'types/company';

interface carouselProps {
  content: JSX.Element[];
  companyID: Company;
  direction: 'x' | 'y';
  title?: string;
  showCarouselOverflow?: boolean;
  hidePrevNextButtons?: boolean;
  draggable?: boolean;
}

const Carousel = ({
  companyID,
  content,
  direction,
  title,
  showCarouselOverflow,
  hidePrevNextButtons,
  draggable,
}: carouselProps): JSX.Element => {
  const [viewportRef, embla] = useEmblaCarousel({
    draggable: draggable,
    containScroll: 'trimSnaps',
    align: 'start',
    axis: direction,
  });
  const [prevBtnEnabled, setPrevBtnEnabled] = useState<boolean>(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState<boolean>(false);
  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    embla.on('reInit', onSelect);
    embla.on('select', onSelect);
    embla.on('resize', onSelect);
  }, [embla, onSelect]);

  useEffect(() => {
    if (!embla) return;
    embla.reInit();
  }, [embla, content, draggable, direction]);

  return (
    <>
      <h2 className={`text-blackBG ${title ? 'block' : 'hidden'}`}>{title}</h2>
      <div className={`carousel ${showCarouselOverflow ? 'overflow-visible' : ''}`}>
        <div className="carousel__viewport" ref={viewportRef}>
          <div className="carousel__container">{content}</div>
        </div>
        {!hidePrevNextButtons && (
          <>
            <PrevButton companyID={companyID} onClick={scrollPrev} enabled={prevBtnEnabled} />
            <NextButton companyID={companyID} onClick={scrollNext} enabled={nextBtnEnabled} />
          </>
        )}
      </div>
    </>
  );
};

export default Carousel;
