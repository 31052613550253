import { useEffect, Dispatch, SetStateAction } from 'react';
import HeaderContact from './HeaderContact';
import HeaderAccount from './HeaderAccount';
import HeaderBasket from './HeaderBasket';
import Company from 'types/company';
import AccountTypes from 'types/account';

interface tabProps {
  companyID: number;
  isMenuOpen: boolean;
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>;
  accountTab: number;
  setAccountTab: Dispatch<SetStateAction<number>>;
  baseUrl: string | undefined;
  apiKey: string | undefined;
  isLoggedIn: boolean;
  setIsLoggedIn: Dispatch<SetStateAction<boolean>>;
  userCredits: AccountTypes['credits'] | null;
  accountDetails: AccountTypes['accountDetails'] | null;
  isAccountDetailsLoading: boolean;
}

function AccountTab({
  companyID,
  isMenuOpen,
  setIsMenuOpen,
  accountTab,
  setAccountTab,
  baseUrl,
  apiKey,
  isLoggedIn,
  setIsLoggedIn,
  userCredits,
  accountDetails,
  isAccountDetailsLoading,
}: tabProps): JSX.Element {
  const displayTab = (): JSX.Element => {
    if (accountTab === 1) {
      return <HeaderContact companyID={companyID} handleClickReset={handleClickReset} />;
    } else if (accountTab === 2) {
      return (
        <HeaderAccount
          companyID={companyID}
          baseUrl={baseUrl}
          apiKey={apiKey}
          setIsMenuOpen={setIsMenuOpen}
          setAccountTab={setAccountTab}
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
          userCredits={userCredits}
          accountDetails={accountDetails}
          isAccountDetailsLoading={isAccountDetailsLoading}
          handleClickReset={handleClickReset}
        />
      );
    } else {
      return (
        <HeaderBasket
          companyID={companyID}
          baseUrl={baseUrl}
          apiKey={apiKey}
          handleClickReset={handleClickReset}
        />
      );
    }
  };

  const handleClickReset = (): void => {
    setIsMenuOpen(false);
    setAccountTab(0);
    document.body.style.overflow = 'auto';
  };

  useEffect(() => {
    window.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        handleClickReset();
      }
    });
  }, []);

  return (
    <div className={`nav-menu--alt ${isMenuOpen ? 'opened' : ''}`}>
      <div className="head__main pb-5 md:pb-10">
        <button
          className={`head__btn ${
            companyID === Company.Ironmongery ? 'hover:text-orange' : 'hover:text-green'
          }`}
          title="Close Menu"
          aria-label="Close Menu"
          type="button"
          onClick={handleClickReset}
        >
          <span className="head__btn--span">
            <svg
              focusable="false"
              className="head__btn--svg"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
            >
              <path d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"></path>
            </svg>
            <span className="hidden xl:block">Close</span>
          </span>
        </button>
      </div>
      <div className="flex flex-col">{displayTab()}</div>
    </div>
  );
}

export default AccountTab;
