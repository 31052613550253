/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, useEffect } from 'react';
// import FocusTrap from 'focus-trap-react';
import getImgUrl from '@utils/getImageUrl';
import truncatedText from '@utils/truncateText';
import Notification from './HeaderBasketNotification';
import Company from 'types/company';
import { Basket } from 'types/product';
import getBasketProductUrl from '@utils/getBasketProductUrl';
import Link from 'next/link';
import getCookies from '@utils/getCookies';
import { useAppSelector } from 'src/hooks/redux';
import dyAddToCart from '@utils/dyAddToCart';
import dyRemoveFromCart from '@utils/dyRemoveFromCart';
import { fbEvent } from '@utils/facebook';
import { FbEventName } from 'types/facebook';
import { useRouter } from 'next/router';
import uuidv4 from '@utils/uuidv4';

interface qtyInputProps {
  qty: number;
  doQtyIncrement: () => void;
  doQtyDecrement: () => void;
  doInputQtyChange: (val: number) => void;
  index: number;
}

// interface basketUpdateConfirmationProps {
//   handleQtyChangeCancel: () => void;
//   handleQtyUpdate: () => void;
//   companyID: Company;
// }

interface productRowProps {
  index: number;
  basketID: number;
  basketEntry: Basket;
  // handleQtyChange: (bool: boolean) => void;
  updateBasketItem: (
    basketID: number,
    skuCode: string,
    quantity: number,
    basketItemId: number
  ) => void;
  removeBasketItem: any;
  showVat: boolean;
  companyID: Company;
  handleClickReset: () => void;
}

const QtyInput = ({
  qty,
  doQtyIncrement,
  doQtyDecrement,
  doInputQtyChange,
  index,
}: qtyInputProps): JSX.Element => (
  <div className="rhd-product__qty">
    <button
      type="button"
      className="rhd-product__qty__input minus"
      aria-label="increment-item-quantity"
      onClick={() => {
        doQtyDecrement();
      }}
    >
      <svg
        className="svg-icon rhd-svg-icon rhd-svg-icon--xs"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
      >
        <path d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" />
      </svg>
    </button>
    <input
      type="text"
      pattern="[0-9]*"
      className="rhd-product__qty__field"
      aria-label={`product-qty-basket-${index}`}
      value={qty}
      onChange={(evt) => {
        if (Number(evt.target.value) < 1000) {
          if (!Number.isNaN(evt.target.value) && !Number.isNaN(parseFloat(evt.target.value))) {
            doInputQtyChange(Number(evt.target.value));
          } else if (evt.target.value === '') {
            doInputQtyChange(0);
          }
        }
      }}
      onBlur={(evt) => {
        if (Number(evt.target.value) <= 0) {
          doInputQtyChange(1);
        }
      }}
    />
    <button
      type="button"
      className="rhd-product__qty__input minus plus"
      aria-label="increment-item-quantity"
      onClick={() => {
        doQtyIncrement();
      }}
      disabled={qty >= 999}
    >
      <svg
        className="svg-icon rhd-svg-icon rhd-svg-icon--xs"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
      >
        <path d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" />
      </svg>
    </button>
  </div>
);

declare const dataLayer: any;
declare const fbq: any;

const HeaderBasketProductRow = ({
  index,
  basketID,
  basketEntry,
  updateBasketItem,
  removeBasketItem,
  showVat,
  companyID,
  handleClickReset,
}: productRowProps): JSX.Element => {
  const [quantity, setQuantity] = useState<number>(0);
  const [isMadeToMeasure, setIsMadeToMeasure] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const { basketItems } = useAppSelector((state) => state.basket);

  const { details } = useAppSelector((state) => state.user);

  const router = useRouter();

  const notPastDate = new Date() <= new Date(basketEntry?.expectedDeliveryDate);

  useEffect(() => {
    setIsLoggedIn(getCookies('authToken') !== '');
  }, []);

  useEffect(() => {
    setQuantity(basketEntry.quantity);
    const isM2M =
      basketEntry.m2mHeight !== null &&
      basketEntry.m2mScrewholes !== null &&
      basketEntry.m2mWidth !== null;
    setIsMadeToMeasure(isM2M);
  }, [basketEntry]);

  // const handleQtyChanged = (bool: boolean): void => {
  //   setQtyChanged(bool);
  //   handleQtyChange(bool);
  // };

  const handleQtyUpdate = (quantityUpdate: number): void => {
    // handleQtyChanged(false);
    const getQuantity = (): number => {
      if (companyID === Company.Ironmongery) {
        return Number(quantityUpdate);
      } else {
        return Number(quantityUpdate) > +basketEntry.stockLevel
          ? +basketEntry.stockLevel
          : Number(quantityUpdate);
      }
    };
    updateBasketItem(basketID, basketEntry.skuCode, getQuantity(), basketEntry.id);
  };

  const doQtyIncrement = (): void => {
    if (quantity < 1000) {
      // GA Add to Cart Event
      dataLayer.push({
        event: 'add_to_cart',
        currency: 'GBP',
        value: basketEntry.prices.price,
        items: [
          {
            item_id: basketEntry.skuCode,
            item_name: basketEntry.name,
            affiliation: 'Online',
            discount: 0.0,
            currency: 'GBP',
            index: 0,
            item_brand: basketEntry.brand,
            item_category: basketEntry.categoryLvl3Name,
            price: basketEntry.prices.price,
            quantity: 1,
          },
        ],
      });

      // Dynamic Yield Add to Cart Event
      dyAddToCart(basketEntry.prices.vatPriceBreaks, basketItems, {
        productId: basketEntry.skuCode,
        quantity: 1,
        itemPrice: basketEntry.prices.vatPrice,
      });

      const authEmail = getCookies('authEmail');
      const price = basketEntry.prices.price;

      const fbEventId = uuidv4();

      // FB Conversions API Add to Cart Event
      fbEvent({
        eventId: fbEventId,
        eventName: FbEventName.ADD_TO_CART,
        eventSourceUrl: router.asPath,
        email: authEmail,
        firstName: details?.firstName,
        lastName: details?.lastName,
        contentIds: [basketEntry.skuCode],
        contentType: 'product',
        contents: [{ id: basketEntry.skuCode, quantity: 1, itemPrice: price }],
        value: quantity * price,
        contentName: basketEntry.name,
      });

      // Facebook Pixel Add to Cart
      fbq(
        'track',
        'AddToCart',
        {
          content_ids: [basketEntry.skuCode],
          content_type: 'product',
        },
        {
          eventID: fbEventId,
        }
      );

      setQuantity(quantity + 1);
      setTimeout(() => {
        handleQtyUpdate(quantity + 1);
      }, 0);
    }
  };

  const doQtyDecrement = (): void => {
    if (quantity > 1) {
      // Dynamic Yield Remove from Cart Event
      dyRemoveFromCart(basketEntry.prices.vatPriceBreaks, basketItems, {
        productId: basketEntry.skuCode,
        quantity: 1,
        itemPrice: basketEntry.prices.vatPrice,
      });

      // GA Remove from Cart Event
      dataLayer.push({
        event: 'remove_from_cart',
        items: [
          {
            item_id: basketEntry.skuCode,
            item_name: basketEntry.name,
            affiliation: 'Online',
            discount: 0,
            currency: 'GBP',
            index: 0,
            item_brand: basketEntry.brand,
            item_category: basketEntry.categoryLvl3Name,
            price: basketEntry.prices.price,
            quantity: 1,
          },
        ],
      });

      setQuantity(quantity - 1);
      handleQtyUpdate(quantity - 1);
    }
  };

  const doInputQtyChange = (val: number): void => {
    setQuantity(val);
    handleQtyUpdate(val);
    // handleQtyChanged(true);
  };

  // const handleQtyChangeCancel = (): void => {
  //   // handleQtyChanged(false);
  //   setQuantity(basketEntry.quantity);
  // };

  const handleDeleteItem = (): void => {
    // Dynamic Yield Remove from Cart Event
    dyRemoveFromCart(basketEntry.prices.vatPriceBreaks, basketItems, {
      productId: basketEntry.skuCode,
      quantity: basketEntry.quantity,
      itemPrice: basketEntry.prices.vatPrice,
    });

    // GA Remove from Cart Event
    dataLayer.push({
      event: 'remove_from_cart',
      items: [
        {
          item_id: basketEntry.skuCode,
          item_name: basketEntry.name,
          affiliation: 'Online',
          discount: 0,
          currency: 'GBP',
          index: 0,
          item_brand: basketEntry.brand,
          item_category: basketEntry.categoryLvl3Name,
          price: basketEntry.prices.price,
          quantity: basketEntry.quantity,
        },
      ],
    });

    // handleQtyChanged(false);
    removeBasketItem(basketID, basketEntry.skuCode, basketEntry.id, index);
  };

  const createBasketEntryName = (): string => {
    if (basketEntry.name !== undefined) {
      return truncatedText(
        `${basketEntry.name.replace(/['"]+/g, '')} ${
          basketEntry.m2mHeight !== null && basketEntry.m2mWidth !== null
            ? `W${basketEntry.m2mWidth}mm x H${basketEntry.m2mHeight}mm ${
                basketEntry.m2mScrewholes !== null
                  ? basketEntry.m2mScrewholes === true
                    ? '- With Screwholes'
                    : '- Without Screwholes'
                  : ''
              }`
            : ''
        }`
      );
    }
    return '';
  };

  const getPrice = (): string => {
    let price;
    if (basketEntry.prices.salePrice > 0) {
      if (showVat) {
        price = basketEntry.prices.salePrice * 1.2;
      } else {
        price = basketEntry.prices.salePrice;
      }
    } else if (isMadeToMeasure && !isLoggedIn) {
      if (showVat) {
        price =
          basketEntry.prices.vatPrice *
          (basketEntry.m2mHeight / 1000) *
          (basketEntry.m2mWidth / 1000);
      } else {
        price =
          basketEntry.prices.price * (basketEntry.m2mHeight / 1000) * (basketEntry.m2mWidth / 1000);
      }
    } else {
      if (showVat) {
        price = basketEntry.prices.vatPrice;
      } else {
        price = basketEntry.prices.price;
      }
    }
    return price.toFixed(2);
  };

  return (
    <>
      <div className="rhd-basket__table__row">
        <div className="rhd-product">
          <div className="rhd-product__img">
            <img
              src={getImgUrl(
                basketEntry.skuCode,
                companyID === Company.Ironmongery ? 'ironmongery' : 'electrical'
              )}
              alt={basketEntry.name}
            />
            {basketEntry.prices.salePrice > 0 ? (
              <div className="rhd-product__sale">
                <span>{`Save ${basketEntry.prices.salePercentage}%`}</span>
              </div>
            ) : null}
          </div>
          <div className="rhd-product__main">
            <Link href={getBasketProductUrl(basketEntry.url)}>
              <a
                className="rhd-product__name rhd-link rhd-link--dark"
                title={`View ${basketEntry.name}`}
                role="button"
                tabIndex={0}
                onClick={handleClickReset}
              >
                {createBasketEntryName()}
              </a>
            </Link>
            <div className="rhd-product__price">
              <p>£{getPrice()}</p>
            </div>
            <div className="rhd-product__controls">
              <div className="rhd-product__controls__qty">
                <QtyInput
                  qty={quantity}
                  doQtyIncrement={doQtyIncrement}
                  doQtyDecrement={doQtyDecrement}
                  doInputQtyChange={doInputQtyChange}
                  index={index}
                />
                {/* {qtyChanged ? (
                  <BasketUpdateConfirmation
                    handleQtyChangeCancel={handleQtyChangeCancel}
                    handleQtyUpdate={handleQtyUpdate}
                    companyID={companyID}
                  />
                ) : null} */}
              </div>
              <button
                type="button"
                className="rhd-btn rhd-btn--focus-dark rhd-btn--icon-tertiary rhd-btn--hbasket"
                onClick={() => {
                  handleDeleteItem();
                }}
              >
                <span className="rhd-btn__inner rhd-btn__inner--hbasket">
                  <span>Remove</span>
                  <svg
                    className="svg-icon rhd-svg-icon rhd-svg-icon--xs"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z" />
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
        {basketEntry.isDirect && (
          <Notification
            notificationType="direct"
            companyID={companyID}
            leadTimeInfo={basketEntry.leadTime}
          />
        )}
        {basketEntry.isHazardous && (
          <Notification notificationType="hazard" companyID={companyID} />
        )}
        {basketEntry.isLongLength && <Notification notificationType="long" companyID={companyID} />}
        {+basketEntry?.stockLevel > 0 && +quantity > +basketEntry?.stockLevel && (
          <>
            {basketEntry.isDirect === false && (
              <Notification
                notificationType="backorder"
                expectedDeliveryDate={basketEntry.expectedDeliveryDate}
                companyID={companyID}
                stockLevel={basketEntry?.stockLevel}
                quantity={quantity}
              />
            )}
          </>
        )}
        {basketEntry?.stockLevel < 1 &&
          !basketEntry?.expectedDeliveryDate &&
          !basketEntry?.isDirect && <Notification notificationType="oos-1" companyID={companyID} />}
        {Number(basketEntry?.stockLevel) < 1 &&
          basketEntry?.expectedDeliveryDate &&
          !basketEntry?.isDirect && (
            <Notification
              notificationType={notPastDate ? 'oos-2' : 'oos-1'}
              expectedDeliveryDate={basketEntry.expectedDeliveryDate}
              companyID={companyID}
            />
          )}
      </div>
    </>
  );
};

export default HeaderBasketProductRow;
